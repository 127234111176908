import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useCartContext } from "../../contexts/CartContext";
import "./cart.css";
import axios from "axios";
import Cookies from "js-cookie";
import { useAuthContext } from "../../contexts/AuthContext";

const Cart = () => {
  const navigate = useNavigate();
  const { cart, removeProduct, total, Fetchcart } = useCartContext();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [address, setAddress] = useState({
    street: "",
    city: "",
    state: "",
    zip: "",
    idnumber: ""
  });


  const { user } = useAuthContext();

  const fetchUserDetails = async () => {
    try {
      const { data } = await axios.get(process.env.REACT_APP_BASE_URL + `/api/auth/getuser/${user.id}`, {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      });


      setAddress(data?.user.address);
    } catch (err) {
      console.error("Error fetching user details:", err);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, [name]: value });
  };

  const editCart = (q, id, price) => {
    const json = JSON.parse(localStorage.getItem("cart"));

    let index = json.findIndex((obj) => obj._id === id);

    if (index !== -1) {
      json[index].quantity = q;
      json[index].total = price * q;
    } else {
      console.log("Object with id not found.");
    }

    localStorage.setItem("cart", JSON.stringify(json));
    Fetchcart();
  };

  const makeRequest = async () => {
    if (cart.length > 0 && address?.idnumber?.length > 0) {
      try {
        setLoading(true);
        const order = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/orders/create`,
          { cart, total, address },
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );
        alert("Order Received");
        localStorage.setItem("cart", JSON.stringify([]));
        Fetchcart();
        setLoading(false);
        setShowModal(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  const deleteProduct = async (id) => {
    await removeProduct(id);
  };


  return (
    <div className="d-flex CartSection">
      <div className="canvas mt-3">
        <div className="d-flex align-items-start justify-content-between">
          <div className="products w-50 p-3">
            {!cart?.length && <p className="text-center">CART IS EMPTY</p>}
            {cart?.map((product) => (
              <div
                key={product._id}
                className="d-flex align-items-start cartCard mt-4"
              >
                <div className="me-3">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}/` + product?.images[0]}
                    alt="img"
                  />
                </div>
                <div>
                  <p className="fw-bold fs-5">{product.title}</p>
                  <div className="d-flex align-items-center my-1">
                    <button
                      className="text-white btn px-2 py-0 fs-6 bg_prim"
                      onClick={() => {
                        if (product.quantity > 1) {
                          editCart(
                            product.quantity - 1,
                            product._id,
                            product.price
                          );
                        }
                      }}
                    >
                      -
                    </button>
                    <p className="px-3 fw-bold fs-5">{product.quantity}</p>
                    <button
                      className="text-white btn px-2 py-0 fs-6 bg_prim"
                      onClick={() =>
                        editCart(
                          product.quantity + 1,
                          product._id,
                          product.price
                        )
                      }
                    >
                      +
                    </button>
                  </div>
                  <p className="fs-5 fw-bold">
                    $ {Number(product.total).toLocaleString()}
                  </p>

                  <button
                    className="btn btn-danger px-2 py-1 mt-2"
                    onClick={() => deleteProduct(product._id)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className="bg-white rounded w-25 p-3">
            <h1 className="fw-bold fs-5 mb-2">Summary</h1>
            <p>Items:</p>
            {cart?.map((product) => (
              <small className="d-block">{product.title}</small>
            )
            )}
            <p className="mt-1">
              Total amount: <b>${Number(total).toLocaleString()}</b>
            </p>
            <button
              className="button mt-3 w-100 mx-auto d-block"
              onClick={() => setShowModal(true)}
            >
              {loading ? "Loading..." : "Check Out"}
            </button>
            <button
              className="button bg_prim mt-3 w-100 mx-auto d-block"
              onClick={() => navigate("/")}
            >
              VOLVER A TIENDA
            </button>
          </div>
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="button bg_prim mt-3 ms-auto d-block text-white" onClick={fetchUserDetails}>AutoFill</button>
            <h2 className="fw-bold">Enter Address Details</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                makeRequest();
              }}

            >
              <div className="form-group text-start mt-2">
                <label className="text-start">ID</label>
                <input
                  type="text"
                  name="idnumber"
                  value={address.idnumber}
                  className="form-control py-1"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group text-start mt-2">
                <label className="text-start">Dirección de Entrega</label>
                <input
                  type="text"
                  name="street"
                  value={address.street}
                  className="form-control py-1"
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group text-start mt-1">
                <label>Ciudad</label>
                <input
                  type="text"
                  className="form-control py-1"
                  name="city"
                  value={address.city}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group text-start mt-1">
                <label>Provincia</label>
                <input
                  type="text"
                  className="form-control py-1"
                  name="state"
                  value={address.state}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="form-group text-start mt-1">
                <label>Código Postal</label>
                <input
                  type="text"
                  className="form-control py-1"
                  name="postalCode"
                  value={address.zip}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <button type="submit" className="button mt-3 me-2">
                Guardar
              </button>
              <button
                type="button"
                className="button mt-3 bg-danger"
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
