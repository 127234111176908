import React, { useState, useEffect } from "react";
import axios from "axios";
import { useAuthContext } from "../../contexts/AuthContext";
import "./auth.css";
import Cookies from "js-cookie";


const EditProfile = () => {
    const [credentials, setCredentials] = useState({
        name: "",
        surname: "",
        email: "",
        address: {
            street: "",
            city: "",
            state: "",
            zip: "",
            phone: ''
        },
    });
    const { user } = useAuthContext();

    useEffect(() => {
        // Fetch current user details
        const fetchUserDetails = async () => {
            try {
                const { data } = await axios.get(process.env.REACT_APP_BASE_URL + `/api/auth/getuser/${user.id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        token: Cookies.get("token"),
                    },
                });

                setCredentials(data?.user);
            } catch (err) {
                console.error("Error fetching user details:", err);
            }
        };

        if (user) fetchUserDetails();
    }, [user]);

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id.startsWith("address.")) {
            const field = id.split(".")[1];
            setCredentials((prev) => ({
                ...prev,
                address: { ...prev.address, [field]: value },
            }));
        } else {
            setCredentials((prev) => ({ ...prev, [id]: value }));
        }
    };

    const handleUpdate = async () => {
        try {
            const res = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/auth/edit/${user.id}`,
                { ...credentials, email: user.email },
                {
                    headers: {
                        "Content-Type": "application/json",
                        token: Cookies.get("token"),
                    },
                }
            );
            const json = await res.data;
            if (json.success) {
                alert("Profile updated successfully!", "primary");
            } else {
                alert("Something went wrong!", "danger");
            }
        } catch (err) {
            console.error("Error updating profile:", err);
        }
    };


    return (
        <div className="canvas mt-3">
            <div className="d-flex mt-5 align-items-center justify-content-center">
                <h1 className="fw-bold ms-3 fs-1">Actualiza tus Datos</h1>
            </div>
            <div className="col-12 d-flex flex-wrap align-items-center mt-4">
                <div className="col-lg-6 mx-auto col-12 mt-3">
                    <div className="mt-2">
                        <label>Nombres</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            value={credentials.name}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-2">
                        <label>Apellidos</label>
                        <input
                            type="text"
                            className="form-control"
                            id="surname"
                            value={credentials.surname}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-2">
                        <label>Teléfono de Contacto</label>
                        <input
                            type="number"
                            className="form-control"
                            id="address.phone"
                            value={credentials.address?.phone}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-2">
                        <label>Dirección de Entrega</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address.street"
                            value={credentials.address?.street}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-2">
                        <label>Ciudad</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address.city"
                            value={credentials.address?.city}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-2">
                        <label>Provincia</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address.state"
                            value={credentials.address?.state}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="mt-2">
                        <label>Código Postal</label>
                        <input
                            type="text"
                            className="form-control"
                            id="address.zip"
                            value={credentials.address?.zip}
                            onChange={handleChange}
                        />
                    </div>
                    <button className="auth-btn mt-3" onClick={handleUpdate}>
                    Guardar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;
