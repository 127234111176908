import React, { useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { BsFillCartFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { useCartContext } from "../contexts/CartContext";
import logo from "../images/logo.png";
import { useAuthContext } from "../contexts/AuthContext";
import axios from "axios";
import Cookies from "js-cookie";

const Navbar = () => {
  const navigate = useNavigate();
  const { cart, orders } = useCartContext();
  const { user, setUser } = useAuthContext()

  const logout = async () => {
    try {
      await axios.post(process.env.REACT_APP_BASE_URL + "/api/auth/logout", {}, {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      });
      setUser(false)
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <nav className="navbar navbar-expand-lg Navbar">
      <div className="canvas">
        <div className="d-flex flex-wrap align-items-center container-fluid">
          <Link to="/" className="navbar-brand fw-bold fs-4">
            {/* Mycellphone<span className="highlightP" style={{fontSize: "12px"}}>.shop</span> */}
            <img src={logo} alt="mycellphone logo" height={30} />
          </Link>
          <button
            className="navbar-toggler d-block d-xl-none ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Link to="/cart" className="text-dark cart-icon d-block d-md-none">
            <div className="items">
              {!cart?.length > 0 ? 0 : cart?.length}
            </div>
            <BsFillCartFill size={25} className="ms-3" />
          </Link>
          <div
            className="collapse navbar-collapse ms-xl-4 justify-content-end align-items-center"
            id="navbarSupportedContent"
          >
            <Link to="/products?q=Smartphones" className="nav-link">
              Smartphones
            </Link>
            <Link to="/products?q=Ordenadores" className="nav-link">
              Ordenadores
            </Link>
            <Link to="/products?q=Smartwatch" className="nav-link">
              Smartwatch
            </Link>
            <Link to="/products?q=Tablets" className="nav-link">
              Tablets
            </Link>
            <div className="d-flex align-items-center mb-3 mb-md-0">
              {/* <form className="d-flex w-100" role="search">
                <div className="d-flex searchBar mx-auto mt-1">
                  <input type={"text"} placeholder="Buscar Productos" />
                  <button
                    className="d-flex justify-content-center align-items-center"
                    type="submit"
                  >
                    <BiSearch color="white" size={20} />
                  </button>
                </div>
              </form> */}
              {/* <form className="d-flex" role="search"> */}
                <div className="d-flex mx-auto mt-1" >
                  <button
                    className="d-flex justify-content-center align-items-center p-2 rounded-pill"
                    type="submit"
                    style={{ background: "black" }}
                  >
                    <BiSearch color="white" size={20} />
                  </button>
                </div>
              {/* </form> */}
            </div>
            {!user ? (
              <>
                <Link to="/login" className="button-outline ms-4">
                  Mi Cuenta
                </Link>
                <Link to={"/register"} className="button ms-2">
                  Registro
                </Link>
              </>
            ) : (
              ""
            )}

            {user && (
              <>
                <div class="dropdown ms-3">
                  <button class="btn border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <FaUser size={25} />
                  </button>
                  <ul class="dropdown-menu">
                    <li><Link class="dropdown-item" to="/profile">Profile</Link></li>
                    <li><Link class="dropdown-item" to="/orders">Orders</Link></li>
                    <li><span class="dropdown-item" onClick={logout} type="button">Logout</span></li>
                  </ul>
                </div>
              </>
            )}
            <Link to="/cart" className="text-dark cart-icon d-none d-md-block">
              <div className="items">
                {!cart?.length > 0 ? 0 : cart?.length}
              </div>
              <BsFillCartFill size={25} className="ms-3" />
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
