import React from "react";
import { Link } from "react-router-dom";
import geslopd from "../images/geslopd.png"
import logo from "../images/logo.png";
import { BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { MdCall, MdMailOutline } from "react-icons/md";
import { AiOutlineClockCircle } from "react-icons/ai";

const Footer = () => {
  return (
    <footer>
      <div className="d-flex canvas justify-content-between flex-wrap footer-rows py-2">
        <div className="footer-col">
          <img src={logo} className="w-100 pe-md-3" />
          <div className="d-flex mt-3 mb-2">
            <a
              href="https://www.instagram.com/transferveneuropa"
              target="_blank"
              className="social_icon"
            >
              <BsInstagram />
            </a>
            <a
              href="https://www.facebook.com/transferveneuropa"
              target="_blank"
              className="social_icon ms-2"
            >
              <FaFacebookF />
            </a>
          
          </div>
          <div className="mt-2 d-flex align-items-center">
              <AiOutlineClockCircle size={20} color="#024e82" />
              <p className="ms-3">L a V de 10 a 18 Hrs.</p>
            </div>
            <div className="mt-2 d-flex align-items-center">
              <MdCall size={20} color="#024e82" />
              <p className=" ms-3">+34 91 993 13 12</p>
            </div>
            <div className="mt-2">
              <MdMailOutline size={20} color="#024e82" />
              <Link to={"/contact"} className="ms-3">
                info.es@transferven.com
              </Link>
            </div>
        </div>
        <div className="footer-col">
          <h6 className="fw-bold">Nosotros</h6>
          <Link to={"/"} className="d-block mt-3 nav-link">
            About
          </Link>
          <Link to={"/"} className="d-block mt-1 nav-link">
            Contact us
          </Link>
          <Link to={"/"} className="d-block mt-1 nav-link">
            Shop
          </Link>
        </div>
        <div className="footer-col">
          <h6 className="fw-bold">Ayuda</h6>
          <Link to={"/costesdeenvios"} className="d-block mt-3 nav-link">
          Costes de Envío
          </Link>
          <Link to={"/devoluciones"} className="d-block mt-1 nav-link">
          Devoluciones
          </Link>
          <Link to={"/"} className="d-block mt-1 nav-link">
            FAQ
          </Link>
          <Link to={"/terms"} className="d-block mt-1 nav-link">
          Términos y Condiciones
          </Link>
        </div>
        <div className="footer-col">
          <h6 className="fw-bold">Información</h6>
          <Link to={"/privacy"} className="d-block mt-3 nav-link">
          Política de Privacidad
          </Link>
          <Link to={"/aviso"} className="d-block mt-1 nav-link">
            Aviso Legal
          </Link>
          <Link to={"/cookies"} className="d-block mt-1 nav-link">
            Política de Cookies
          </Link>
          <Link to={"/protection"} className="d-block mt-1 nav-link">
            Protección de Datos
          </Link>
        </div>
        <div className="footer-colsm">
          <img src={geslopd} height={125} />
        </div>
      </div>
      <p className="text-center mt-3">
        Copyright 2025 ©️ Tutiendadelmovil.com • Todos los Derechos Reservados
      </p>
    </footer>
  );
};

export default Footer;
