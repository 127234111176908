import React, { useEffect, useState } from "react";
import "./product.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useCartContext } from "../../contexts/CartContext";
import Cookies from "js-cookie";
import { TbTruckDelivery } from "react-icons/tb";
import { FaInfoCircle } from "react-icons/fa";
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton } from 'react-share';
import { FacebookIcon, WhatsappIcon, XIcon } from 'react-share';

const Product = () => {
  const { cart, addProduct } = useCartContext();
  const [product, setProduct] = useState({});
  const navigate = useNavigate();
  const [checkOut, setCheckOut] = useState(false);
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const id = useParams();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const shareUrl = window.location.href; // Current page URL

  useEffect(() => {
    const FetchProduct = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/products/getproduct/${id.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );
        setProduct(res.data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    FetchProduct();
  }, []);


  const encodeUrl = (url) => {
    // Split the URL at the last '/' to get the file name separately
    const parts = url.split('/');
    const fileName = parts.pop();

    // Encode the file name
    const encodedFileName = encodeURIComponent(fileName);

    // Reconstruct the URL with the encoded file name
    return `${parts.join('/')}/${encodedFileName}`;
  };

  const HandleAddCart = () => {
    if (!cart?.filter((elem) => { return elem._id === product._id }).length > 0) {
      addProduct(product, quantity, product.price * quantity);
    }
    navigate("/cart");
  };

  useEffect(() => {
    setLoading(true);
    const products = cart?.products?.filter((elem) => {
      return elem.productId === product._id;
    });
    if (products?.length > 0) {
      setCheckOut(true);
    }
    setLoading(false);
  }, []);

  if (loading) return "LOADING!";

  return (
    <div className="Product mt-3">
      <div className="d-md-flex justify-content-between canvas">
        <div className="d-lg-flex flex-lg-row-reverse Slider me-lg-5 me-md-3" style={{ width: "40%" }}>
          <Swiper
            style={{
              "--swiper-navigation-color": "#fff",
              "--swiper-pagination-color": "#fff",
            }}
            loop={true}
            spaceBetween={10}
            navigation={true}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {product.images?.map((elem) => {
              const sanitizedElem = encodeURIComponent(`${process.env.REACT_APP_BASE_URL}/${elem}`);
              return (
                <SwiperSlide key={elem}>
                  <div
                    style={{
                      background: `url(${process.env.REACT_APP_BASE_URL}/${product.images[0]})`,
                    }}
                    className="productImg"
                  ></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            direction="vertical"
            grabCursor={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper flex-column me-3  d-none d-lg-flex"
          >
            {product.images?.map((elem) => {
              const sanitizedElem = decodeURIComponent(`${process.env.REACT_APP_BASE_URL}/${elem}`);

              return (
                <SwiperSlide key={elem}>
                  <div
                    style={{
                      backgroundImage: `url('${sanitizedElem}')`
                    }}
                    className="SproductImg"
                  ></div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <div className="details w-100">
          <p
            className="text-muted d-md-block d-none"
            style={{ wordSpacing: "5px" }}
          >
            Home › {product.category} ›{" "}
            <span className="fw-bold text-dark">{product.subcategory}</span>{" "}
          </p>
          <h1 className="fw-bold fs-2 mt-3">{product.title}</h1>
          <h1 className="fw-bold fs-2 mt-3 price">$ {product.price * quantity}</h1>

          <div className="d-flex align-items-center mt-3">
            <button className="text-white btn px-3  py-1  fs-5 bg_prim" onClick={() => setQuantity(quantity > 1 ? quantity - 1 : 1)}>-</button>
            <p className="px-5 fw-bold fs-5">{quantity}</p>
            <button className="text-white btn px-3  py-1  fs-5 bg_prim" onClick={() => setQuantity(quantity + 1)}>+</button>
          </div>

          <div className="mt-3" style={{ color: "#606060" }}>
            <p>Impuestos incluidos</p>
            <p className="mt-1"><FaInfoCircle color="black" size={22} /> Canon Digital <b>3,25 €</b> incluido en el precio.</p>

            <p style={{ color: "green" }} className="mt-2">
              <TbTruckDelivery color="green" size={30} />  ¡Envío gratis! *
            </p>
            <small>*Excluido Ceuta v Melis</small>
          </div>

          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '20px' }}>
                <span className="mb-0">COMPARTIR</span>
                <FacebookShareButton url={shareUrl} quote={`Check out this product: ${product.title}`}>
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={`Check out this product: ${product.title}`}>
                    <XIcon size={32} round />
                </TwitterShareButton>
                <WhatsappShareButton url={shareUrl} title={`Check out this product: ${product.title}`}>
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </div>


          {!checkOut ? (
            <div className="d-flex mt-3">
              <button className="cartBtn" onClick={HandleAddCart}>
                ADD TO CART
              </button>


              <button className="cartBtn ms-3" onClick={() => navigate("/")}>
                VOLVER A TIENDA
              </button>
            </div>
          ) : (
            <button className="cartBtn mt-3">Continue to checkout</button>
          )}
          <div className="description mt-3">
            <p className="fw-bold">Product Description:</p>
            <p>{product.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
