import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

const CartContext = createContext();

export default function CartContextProvider({ children }) {
  const [cart, setCart] = useState([]);
  const [total, setTotal] = useState(0);
  const [orders, setOrders] = useState([]);

  const addProduct = async (product, quantity, total) => {
    try {
      if (localStorage.getItem("cart") && JSON.parse(localStorage.getItem("cart"))?.length > 0) {
        let json = [];
        json.push(...JSON.parse(localStorage.getItem("cart")), {
          ...product,
          total,
          quantity,
        });
        localStorage.setItem("cart", JSON.stringify(json));
      } else {
        localStorage.setItem(
          "cart",
          JSON.stringify([{ ...product, total, quantity }])
        );
      }
      const sum = cart?.reduce((curr, a) => curr.total + a, 0);
      setTotal(sum);
      Fetchcart();
    } catch (err) {
      console.log(err);
    }
  };

  const removeProduct = async (id) => {
    try {
      const getCart = localStorage.getItem("cart");
      const filteredCart = JSON.parse(getCart)?.filter((element) => {
        return element._id !== id;
      });

      localStorage.setItem("cart", JSON.stringify(filteredCart));
      Fetchcart();
    } catch (err) {
      console.log(err);
    }
  };

  const Fetchcart = async () => {
    const data = JSON.parse(localStorage.getItem("cart"));
    setCart(data);
    const sum = data?.reduce((acc, curr) => curr.total + acc, 0);
    setTotal(sum);
  };

  const Fetchorders = async () => {
    if (localStorage.getItem("token")) {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/orders/getOrder`,
        {
          headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
          },
      }
      );
      const data = res.data;
      setOrders(data);
    }
  };

  useEffect(() => {
    Fetchcart();
    if (localStorage.getItem("token")) {
      Fetchorders();
    }
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        addProduct,
        removeProduct,
        Fetchcart,
        setCart,
        orders,
        Fetchorders,
        total,
        setTotal,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export function useCartContext() {
  return useContext(CartContext);
}
