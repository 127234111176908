import React from "react";
import SmartPhone from "../images/SMARTPHONES.png";
import ORDENADORES from "../images/ORDENADORES.png";
import WEAREABLES from "../images/WEAREABLES.png";
import TABLETS from "../images/TABLETS.png";
import { useNavigate } from "react-router-dom"


const Collection = () => {
  const navigate = useNavigate()

  return (
    <div>
      <h1 className="text-center fw-bold mt-5">Ofertas y Promociones</h1>
      <div
        className="canvas d-flex justify-content-between flex-lg-row  collections mt-3 gap-3"
        style={{ flexWrap: window.matchMedia("(min-width: 1100px)").matches ? "nowrap" : "wrap" }}
      >
        <div className="col-md-3 col-5 text-center px-4 py-3 rounded-5" type="button" style={{ background: "#f7f7f7" }} onClick={() => navigate("/products?q=Smartphones")}>
          <img src={SmartPhone} className="w-100" />
          <p className="fs-5">Smartphones</p>
        </div>
        <div className="col-md-3 col-5 text-center px-4 py-3 rounded-5" type="button" style={{ background: "#f7f7f7" }} onClick={() => navigate("/products?q=Ordenadores")}>
          <img src={ORDENADORES} className="w-100" />
          <p className="fs-5">Ordenadores</p>
        </div>
        <div className="col-md-3 col-5 text-center px-4 py-3 rounded-5" type="button" style={{ background: "#f7f7f7" }} onClick={() => navigate("/products?q=Smartwatch")}>
          <img src={WEAREABLES} className="w-100" />
          <p className="fs-5">Smartwatch</p>
        </div>
        <div className="col-md-3 col-5 text-center px-4 py-3 rounded-5" type="button" style={{ background: "#f7f7f7" }} onClick={() => navigate("/products?q=Tablets")}>
          <img src={TABLETS} className="w-100" />
          <p className="fs-5">Tablets</p>
        </div>
      </div>

    </div>
  );
};

export default Collection;
