import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom"; // For getting query parameters
import Card from "../../components/Card";
import "./product.css";

const Products = () => {
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const location = useLocation(); // Get the current URL location

  // Extract query parameter `q` from the URL
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get("q");

  // Fetch products from backend
  const fetchProducts = async (brand = "", query = "") => {
    const url =
      process.env.REACT_APP_BASE_URL +
      `/api/products/getproducts${brand || query ? `?${brand ? `brand=${brand}` : `q=${query}`}` : ""}`;
    const response = await fetch(url);
    const data = await response.json();
    setProducts(data);

    // Update brands based on filtered products
    calculateBrands(data);
  };

  // Calculate unique brands and their counts from products
  const calculateBrands = (products) => {
    const brandCounts = products.reduce((acc, product) => {
      acc[product.brand] = (acc[product.brand] || 0) + 1;
      return acc;
    }, {});

    const brandList = Object.entries(brandCounts).map(([brand, count]) => ({
      brand,
      count,
    }));

    setBrands(brandList);
  };

  useEffect(() => {
    fetchProducts("", query); // Fetch products based on query parameter
  }, [query]); // Re-run when `query` changes

  // Handle brand filter change
  const handleBrandChange = (brand) => {
    setSelectedBrand(brand);
    fetchProducts(brand, query); // Pass the current query when filtering by brand
  };

  return (
    <div className="canvas mt-5">
      <div className="row gap-y-3">
        {/* Filters */}
        <div className="col-md-3">
          <h3 className="mb-3">Filter by Brand</h3>
          <ul className="list-group brandfilter">
            {brands.map(({ brand, count }) => (
              <li
                key={brand}
                className={`list-group-item ${selectedBrand === brand ? "active" : ""}`}
                onClick={() => handleBrandChange(brand)}
                style={{ cursor: "pointer" }}
              >
                {brand} <span className="badge float-end">{count}</span>
              </li>
            ))}
            <li
              className={`list-group-item ${selectedBrand === "" ? "active" : ""}`}
              onClick={() => handleBrandChange("")}
              style={{ cursor: "pointer" }}
            >
              All <span className="badge bg-secondary float-end">{products.length}</span>
            </li>
          </ul>
        </div>

        {/* Product List */}
        <div className="col-md-9 mt-md-0 mt-3">
          <div className="d-flex gap-3 flex-wrap align-items-center justify-content-center justify-content-md-start">
            {products.map((product) => (
              <div style={{ width: "300px" }} key={product._id}>
                <Card product={product} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
