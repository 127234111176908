import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";


const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true)
        const { data } = await axios.get(process.env.REACT_APP_BASE_URL + "/api/auth/userinfo", {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        });
        setUser(data);
        setLoading(false)
      } catch (err) {
        // console.error("User Not Login");
        setUser(false)
        setLoading(false)
      }
    };

    fetchUserData();
  }, []);


  if (loading) return "loading..";

  return (
    <AuthContext.Provider value={{
      user, setUser, loading, setLoading
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}