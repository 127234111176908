import React, { useState } from "react";
import "./auth.css";
import axios from "axios";
import { Navigate, useNavigate, Link } from "react-router-dom";
import registerImg from "../../images/register1.svg";
import { useAuthContext } from "../../contexts/AuthContext";

const Register = () => {
  const [credentials, setCredentials] = useState({});
  const { user } = useAuthContext()
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handleRegister = async () => {
    if (document.getElementById("flexCheckDefault").checked) {
      if (credentials.password === credentials.cpassword) {
        try {
          setLoading(true)
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/auth/register`,
            credentials,

          );
          const json = await res.data;
          setCredentials({ email: "", password: "", cpassword: "" });
          alert("¡Tu Cuenta ha sido creada!", "primary");
          navigate("/login")
          setLoading(false)
        } catch (err) {
          console.log(err.response.data);
          setLoading(false)
        }
      } else {
        alert("CONTRASEÑAS NO COINCIDEN!");
        setLoading(false)
      }
    } else {
      alert("Acepta nuestros Términos y Condiciones");

    }
  };

  if (user) return <Navigate to={"/"} />;

  return (
    <div className="canvas mt-3">
      <div className="d-flex  mt-5 align-items-center">
        {/* <div style={{fontWeight: "bold",  fontSize: "1.1rem", padding: window.matchMedia("(max-width: 550px)").matches ? "0px 11px":  "10px 20px", borderRadius: "50%", border: "1.99px solid black" }}>i</div> */}
        <h1 className="fw-bold ms-3 fs-1">¡Abre tu Cuenta!</h1>
      </div>
      <div className="col-12 d-flex flex-wrap align-items-center mt-4">
        <div className="col-lg-6 col-12 mt-3">
          <img src={registerImg} className="col-lg-8 col-10 mx-auto d-block" />
        </div>
        <div className="col-lg-6 col-12 mt-3">

          <div className="mt-2">
            <label>Email</label>
            <input
              type={"email"}
              className="form-control"
              value={credentials.email}
              onChange={(e) => handleChange(e)}
              id="email"
            />
          </div>
          <div className="mt-2">
            <label>Contraseña</label>
            <input
              type={"password"}
              className="form-control"
              placeholder="Crea tu contraseña"
              id="password"
              value={credentials.password}
              onChange={(e) => handleChange(e)}
            />
            <input
              type={"password"}
              className="form-control mt-2"
              placeholder="Confirmar Contraseña"
              id="cpassword"
              value={credentials.cpassword}
              onChange={(e) => handleChange(e)}
            />
          </div>

          <div className="d-flex align-items-start form-check mt-3">
            <input
              className="form-check-input mb-0"
              style={{ width: "25px", height: "20px", border: "2px solid black" }}
              type="checkbox"
              id="flexCheckDefault"
            />
            <label
              className="form-check-label text-muted ms-2"
              htmlFor="flexCheckDefault"
              style={{ width: "calc(100% - 35px)" }}
            >
              Haciendo click aquí, aceptas nuestros{" "}
              <Link to="/terms" target="_blank">
                Términos y Condiciones
              </Link>{" "}
              y
              <Link to={"/protection"} target="_blank">
                {" "}
                Política General en materia de Protección de Datos
              </Link>{" "}
              de Tu Tienda del Móvil y nos autorizas a contactarte por E-mail, SMS y Whatsapp.
            </label>
          </div>

          <button className="auth-btn mt-3" onClick={handleRegister} disabled={loading}>
            {loading ? "Enviando" : "REGISTRARME"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Register;
